
$border-radius:               .25rem !default;
$transition-base:             all .2s ease-in-out !default;
$gray-500: #adb5bd !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$box-shadow:                  0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
$shapes-primary-colors: () !default;

.shadow { box-shadow: $box-shadow !important; }

$shapes-primary-colors: map-merge((
  "step-1-gradient-bg":         #281483,
  "step-2-gradient-bg":         #8f6ed5,
  "step-3-gradient-bg":         #d782d9,
  "span-1-bg":                  #53f,
  "span-2-bg":                  #4553ff,
  "span-3-bg":                  #4f40ff,
  "span-4-bg":                  #25ddf5,
  "span-5-bg":                  #1fa2ff
), $shapes-primary-colors);

$shapes-default-colors: () !default;
$shapes-default-colors: map-merge((
  "step-1-gradient-bg":         #7795f8,
  "step-2-gradient-bg":         #6772e5,
  "step-3-gradient-bg":         #555abf,
  "span-1-bg":                  #7795f8,
  "span-2-bg":                  #7b9aff,
  "span-3-bg":                  #6f8ff8,
  "span-4-bg":                  #76eea7,
  "span-5-bg":                  #6adaff
), $shapes-default-colors);
$shapes-light-colors: () !default;
$shapes-light-colors: map-merge((
  "step-1-gradient-bg":         #b2cbe1,
  "step-2-gradient-bg":         #f6f9fc,
  "step-3-gradient-bg":         #f6f9fc,
  "span-1-bg":                  #b4cce1,
  "span-2-bg":                  #c5dbef,
  "span-3-bg":                  #b9d5ed,
  "span-4-bg":                  #74e4a2,
  "span-5-bg":                  #008169
), $shapes-light-colors);

$shapes-dark-colors: () !default;
$shapes-dark-colors: map-merge((
  "step-1-gradient-bg":         #32325d,
  "step-2-gradient-bg":         #32325d,
  "step-3-gradient-bg":         #32325d,
  "span-1-bg":                  #2e2e57,
  "span-2-bg":                  #2b2b58,
  "span-3-bg":                  #25254d,
  "span-4-bg":                  #d782d9,
  "span-5-bg":                  #008169
), $shapes-dark-colors);

@function section-color($key: "primary") {
	@return map-get($section-colors, $key);
}

// Lines colors

@function shapes-primary-color($key: "step-1-gradient-bg") {
	@return map-get($shapes-primary-colors, $key);
}

@function shapes-default-color($key: "step-1-gradient-bg") {
	@return map-get($shapes-default-colors, $key);
}

@function lines-light-color($key: "step-1-gradient-bg") {
	@return map-get($shapes-light-colors, $key);
}

@function shapes-dark-color($key: "step-1-gradient-bg") {
	@return map-get($shapes-dark-colors, $key);
}

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}


.card {
    position: relative;
}

.profile-page {
    .card-profile {
        margin-top: -150px;

        .card-profile-image {
            position: relative;
            //min-height: 130px;

            img {
                max-width: 180px;
                border-radius: $border-radius;
                @extend .shadow;
                transform: translate(-50%,-30%);
                position: absolute;
                left: 50%;
                transition: $transition-base;

                &:hover {
                    transform: translate(-50%, -33%);
                }
            }
        }

        .card-profile-stats {
            padding: 1rem 0;

            > div {
                text-align: center;
                margin-right: 1rem;
                padding: .875rem;

                &:last-child {
                    margin-right: 0;
                }

                .heading {
                    font-size: 1.1rem;
                    font-weight: bold;
                    display: block;
                }
                .description {
                    font-size: .875rem;
                    color: $gray-500;
                }
            }
        }

        .card-profile-actions {
            padding: .875rem;
        }

        @include media-breakpoint-down(xs) {
            .card-profile-actions {
                margin-top: 110px;
            } 
        }

        @include media-breakpoint-between(sm, md) {
            .card-profile-stats {
                margin-top: 30px;
            }
        }
    }
}

// Profile cover
.section-profile-cover {
    height: 580px;
    background-size: cover;
    background-position: center center;
}

@include media-breakpoint-down(md) {
    .section-profile-cover {
        height: 400px;
    }
}

// Circle sections
.section-shaped {
    //margin: 180px 0;
    //position: relative;

    .stars-and-coded{
        margin-top: 8rem;
    }
    
    .shape {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        span {
            position: absolute;
        }

        +.container {
            position: relative;
            padding-top: 6rem;
            padding-bottom: 0rem;
            height: 100%;
        }

        &.shape-skew {

            +.container {
                padding-top: 0;

                .col {
                    margin-top: -100px;
                }
            }

            + .shape-container {
                padding-top: 18rem;
                padding-bottom: 19rem;
            }
        }
    }

    .shape-skew {
        transform: skewY(-8deg);
        transform-origin: 0;

        span {
            transform: skew(8deg);
        }
    }

    // Color variations
    .shape-primary {
        background: linear-gradient(150deg, shapes-primary-color("step-1-gradient-bg") 15%, shapes-primary-color("step-2-gradient-bg") 70%, shapes-primary-color("step-3-gradient-bg") 94%);

        :nth-child(1) {
            background: shapes-primary-color("span-1-bg");
        }

         :nth-child(2) {
            background: shapes-primary-color("span-2-bg");
        }

         :nth-child(3) {
            background: shapes-primary-color("span-3-bg");
        }

         :nth-child(4) {
            background: shapes-primary-color("span-4-bg");
        }

         :nth-child(5) {
            background: shapes-primary-color("span-5-bg");
        }
    }

    .shape-default {
        background: linear-gradient(150deg, shapes-default-color("step-1-gradient-bg") 15%, shapes-default-color("step-2-gradient-bg") 70%, shapes-default-color("step-3-gradient-bg") 94%);

         :nth-child(1) {
            background: shapes-default-color("span-1-bg");
        }

         :nth-child(2) {
            background: shapes-default-color("span-2-bg");
        }

         :nth-child(3) {
            background: shapes-default-color("span-3-bg");
        }

         :nth-child(4) {
            background: shapes-default-color("span-4-bg");
        }

         :nth-child(5) {
            background: shapes-default-color("span-5-bg");
        }
    }

    .shape-light {
        background: linear-gradient(150deg, shapes-light-color("step-1-gradient-bg") 15%, shapes-light-color("step-2-gradient-bg") 70%, shapes-light-color("step-3-gradient-bg") 94%);

         :nth-child(1) {
            background: shapes-light-color("span-1-bg");
        }

         :nth-child(2) {
            background: shapes-light-color("span-2-bg");
        }

         :nth-child(3) {
            background: shapes-light-color("span-3-bg");
        }

         :nth-child(4) {
            background: shapes-light-color("span-4-bg");
        }

         :nth-child(5) {
            background: shapes-light-color("span-5-bg");
        }
    }

    .shape-dark {
        background: linear-gradient(150deg, shapes-dark-color("step-1-gradient-bg") 15%, shapes-dark-color("step-2-gradient-bg") 70%, shapes-dark-color("step-3-gradient-bg") 94%);

         :nth-child(1) {
            background: shapes-dark-color("span-1-bg");
        }

         :nth-child(2) {
            background: shapes-dark-color("span-2-bg");
        }

         :nth-child(3) {
            background: shapes-dark-color("span-3-bg");
        }

         :nth-child(4) {
            background: shapes-dark-color("span-4-bg");
        }

         :nth-child(5) {
            background: shapes-dark-color("span-5-bg");
        }
    }

    .shape-style-1 {
        span {
            height: 120px;
            width: 120px;
            border-radius: 50%;
        }

        .span-200 {
            height: 200px;
            width: 200px;
        }

        .span-150 {
            height: 150px;
            width: 150px;
        }

        .span-100 {
            height: 100px;
            width: 100px;
        }

        .span-75 {
            height: 75px;
            width: 75px;
        }

        .span-50 {
            height: 50px;
            width: 50px;
        }

        :nth-child(1) {
            left: -4%;
            bottom: auto;
            background: rgba(255, 255, 255, .1);
        }

        :nth-child(2) {
            right: 4%;
            top: 10%;
            background: rgba(255, 255, 255, .1);
        }

        :nth-child(3) {
            top: 280px;
            right: 5.66666%;
            background: rgba(255, 255, 255, .3);
        }

        :nth-child(4) {
            top: 320px;
            right: 7%;
            background: rgba(255, 255, 255, .15);
        }

        :nth-child(5) {
            top: 38%;
            left: 1%;
            right: auto;
            background: rgba(255, 255, 255, .05);
        }

        :nth-child(6) {
            width: 200px;
            height: 200px;
            top: 44%;
            left: 10%;
            right: auto;
            background: rgba(255, 255, 255, .15);
        }

        :nth-child(7) {
            bottom: 50%;
            right: 36%;
            background: rgba(255, 255, 255, .04);
        }

        :nth-child(8) {
            bottom: 70px;
            right: 2%;
            background: rgba(255, 255, 255, .2);
        }

        :nth-child(9) {
            bottom: 1%;
            right: 2%;
            background: rgba(255, 255, 255, .1);
        }

        :nth-child(10) {
            bottom: 1%;
            left: 1%;
            right: auto;
            background: rgba(255, 255, 255, .05);
        }

        @include media-breakpoint-down(md) {
            span {
                height: 120px;
            }
        }

        @include media-breakpoint-down(sm) {
            span {
                height: 90px;
            }
        }

        // shape color variations
        &.shape-primary {
            background: linear-gradient(150deg, shapes-primary-color("step-1-gradient-bg") 15%, shapes-primary-color("step-2-gradient-bg") 70%, shapes-primary-color("step-3-gradient-bg") 94%);
        }

        &.shape-default {
            background: linear-gradient(150deg, shapes-default-color("step-1-gradient-bg") 15%, shapes-default-color("step-2-gradient-bg") 70%, shapes-default-color("step-3-gradient-bg") 94%);
        }

        &.shape-light {
            background: linear-gradient(150deg, shapes-light-color("step-1-gradient-bg") 15%, shapes-light-color("step-2-gradient-bg") 70%, shapes-light-color("step-3-gradient-bg") 94%);

        }

        &.shape-dark {
            background: linear-gradient(150deg, shapes-dark-color("step-1-gradient-bg") 15%, shapes-dark-color("step-2-gradient-bg") 70%, shapes-dark-color("step-3-gradient-bg") 94%);
        }
    }

    // Styles - works with a color variation class (e.g shapes-default)
    .shape-style-2 {
        span {
            height: 190px;
        }

        .span-sm {
            height: 100px;
        }

         :nth-child(1) {
            width: 33.33333%;
            top: 0;
            left: -16.66666%;
        }

         :nth-child(2) {
            width: 33.33333%;
            top: 0;
            left: 16.66666%;
            right: auto;
        }

         :nth-child(3) {
            width: 33.33333%;
            left: 49.99999%;
            bottom: auto;
        }

         :nth-child(4) {
            width: 33.33333%;
            top: 55%;
            right: -16.66666%;
        }

         :nth-child(5) {
            width: 33.33333%;
            bottom: 0;
        }

        @include media-breakpoint-down(md) {
            span {
                height: 120px;
            }
        }

        @include media-breakpoint-down(sm) {
            span {
                height: 90px;
            }
        }
    }

    .shape-style-3 {
        span {
            height: 140px;
        }

        .span-sm {
            height: 100px;
        }

        :nth-child(1) {
            width: 66%;
            left: -16.66666%;
            bottom: auto;
        }

        :nth-child(2) {
            width: 40%;
            top: 54%;
            //height: 320px;
            right: -16.66666%;
        }

        :nth-child(3) {
            width: 33.33333%;
            top: 34%;
            left: -16.66666%;
            right: auto;
        }

        :nth-child(4) {
            width: 60%;
            bottom: 0;
            right: -16.66666%;
            opacity: .6;
        }

        :nth-child(5) {
            width: 33.33333%;
            bottom: 0;
        }

        @include media-breakpoint-down(md) {
            span {
                height: 120px;
            }
        }

        @include media-breakpoint-down(sm) {
            span {
                height: 90px;
            }
        }
    }

}